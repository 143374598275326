import { MdDevicesOther, MdAnimation, MdOutlineCode } from "react-icons/md";
import { RiTimerFlashFill } from "react-icons/ri";

import "./about.scss";

function About() {
  return (
    <div className="aboutSection section" id="about">
      <div className="container">
        <div className="box">
          <div data-aos="slide-right" className="img">
            <img
              src="./images/portfolio.webp"
              alt="mohamed waled the website owner"
            />
          </div>
          <div className="about">
            <div data-aos="slide-left" className="mainTitle">
              <h1>about</h1>
            </div>
            <div data-aos="slide-left" className="jobTitle">
              <h2>front-end developer</h2>
            </div>
            <div data-aos="slide-left" className="bio">
              <p>
                Front-End Development isn't just a bunch of buttons and colors;
                It extends to understanding someone's ideas and, on top of that,
                transforming them into a true story by bringing their vision to
                life!
              </p>
              <p>
                Over the past two years, I've worked to sharpen my front-end
                skills by building projects, learning new technologies, and
                implementing creative ideas. Along the way, I've learned core
                front-end technologies such as HTML, CSS, JavaScript. I then
                took my productivity to another level by leveraging tools like
                Git, GitHub, Sass and PugJS, ending my two-year journey by
                learning more advanced technologies such as React.js and Next.js
                alongside with TypeScript, Tailwind CSS and Framer Motion.
              </p>
              <p>
                My ultimate goal is to become a competent and knowledgeable
                Software Engineer who comprehends their duties and performs them
                proficiently.
              </p>
              <p>
                Feel free to explore my projects below to see what I’ve been
                working on, and don’t hesitate to reach out via the "Say Hello"
                button or the contact form at the bottom of the page. Let's
                cooperate on transforming ideas into remarkable stories!
              </p>
            </div>
            <div data-aos="fade-up" className="services">
              <div className="icon">
                <RiTimerFlashFill />
                <p className="title">on time</p>
              </div>
              <div className="icon">
                <MdDevicesOther />
                <p className="title">responsive</p>
              </div>
              <div className="icon">
                <MdAnimation />
                <p className="title">animated</p>
              </div>
              <div className="icon">
                <MdOutlineCode />
                <p className="title">clean code</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
