const projectData = [
  {
    id: 0,
    projectName: "road to hero",
    projectNameDescription:
      "novel’s website that provides a better reading experience.",
    projectDescription:
      "An online reading platform for serialized light novels. Chapters are retrieved straight from the novel's server using a Node.js-powered Discord bot, which arranges them in an orderly and easily navigable manner. Additionally, the website has a tracking feature that lets users easily keep tabs on their progress. Ideal for people who prefer a simplified reading experience with integrated progress monitoring!",
    projectImage: "./images/projects/road-to-hero.webp",
    projectRepo: "https://github.com/Mohamed-Waled/Road-To-Hero",
    projectLive: "https://road-to-hero.vercel.app/",
    technologies: [
      {
        name: "react.js",
        icon: "FaReact",
      },
      {
        name: "next.js",
        icon: "SiNextdotjs",
      },
      {
        name: "TypeScript",
        icon: "SiTypescript",
      },
      {
        name: "tailwindCSS",
        icon: "SiTailwindcss",
      },
      {
        name: "node.js",
        icon: "SiNodedotjs",
      },
      {
        name: "vercel",
        icon: "SiVercel",
      },
    ],
    isNew: true,
  },
  {
    id: 1,
    projectName: "team website",
    projectNameDescription:
      "a website template that can be a good fit for a company or a product.",
    projectDescription:
      "A versatile website template designed for businesses, offering a range of essential sections to meet various needs. It includes: A landing section to introduce your business, An articles section to showcase featured content, A gallery section to highlight key images, A features section to present your services, and much more.",
    projectImage: "./images/projects/team-website.webp",
    projectRepo: "https://github.com/Mohamed-Waled/webSite",
    projectLive: "https://landing-page.mohamedwaled.com/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
      {
        name: "SASS",
        icon: "SiSass",
      },
      {
        name: "vanilla JavaScript",
        icon: "SiJavascript",
      },
    ],
    featured: true,
  },
  {
    id: 2,
    projectName: "clipboard landing page",
    projectNameDescription:
      "a landing page template built for training purposes.",
    projectDescription:
      "A sleek and organized clipboard-style landing page that serves as an ideal homepage for your business, application, or portfolio. Its clean and modern design ensures clarity and adaptability, allowing you to customize it to suit your specific needs.",
    projectImage: "./images/projects/clipboard-landing-page.webp",
    projectRepo: "https://github.com/Mohamed-Waled/Clipboard-Landing-Page",
    projectLive: "https://mohamed-waled.github.io/Clipboard-Landing-Page/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
    ],
    isTraining: true,
  },
  {
    id: 3,
    projectName: "personal portfolio webpage",
    projectNameDescription: "a personal portfolio built for training purposes.",
    projectDescription:
      "A personal portfolio webpage designed to include everything you need to showcase your work effectively. It features a section to introduce yourself, a dedicated area to display your projects, and the option to add detailed pages for each project to provide in-depth information. The design is rounded out with a footer section that links to all your social media accounts, ensuring easy connectivity.",
    projectImage: "./images/projects/personal-portfolio-webpage.webp",
    projectRepo: "https://github.com/Mohamed-Waled/Personal-Portfolio-Webpage",
    projectLive: "https://mohamed-waled.github.io/Personal-Portfolio-Webpage/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
    ],
    isTraining: true,
  },
  {
    id: 4,
    projectName: "fylo landing page",
    projectNameDescription:
      "a landing page template built for training purposes.",
    projectDescription:
      "A Fylo-inspired landing page featuring a clean two-column layout, perfect for use as a homepage for your business, application, or portfolio. Its tidy and modern design ensures clarity and professionalism, while offering the flexibility to customize and add content to suit your needs.",
    projectImage: "./images/projects/fylo-landing-page.webp",
    projectRepo:
      "https://github.com/Mohamed-Waled/Fylo-Landing-Page-With-Two-Column-Layout",
    projectLive:
      "https://mohamed-waled.github.io/Fylo-Landing-Page-With-Two-Column-Layout/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
    ],
    isTraining: true,
  },
  {
    id: 5,
    projectName: "technical documentation page",
    projectNameDescription:
      "a website template that can be a good fit for technical documentation.",
    projectDescription:
      "A versatile technical documentation page that can be easily customized to serve as a terms of use or privacy policy page for your website. It offers flexibility to include and organize any additional information you need.",
    projectImage: "./images/projects/technical-documentation-page.webp",
    projectRepo:
      "https://github.com/Mohamed-Waled/Technical-Documentation-Page",
    projectLive:
      "https://mohamed-waled.github.io/Technical-Documentation-Page/",
    technologies: [
      {
        name: "html5",
        icon: "SiHtml5",
      },
      {
        name: "css3",
        icon: "SiCss3",
      },
    ],
    isTraining: true,
  },
  {
    id: 6,
    projectName: "random quote machine",
    projectNameDescription:
      "a quote machine that randomizes quotes from a certain API.",
    projectDescription:
      "A Random Quote Machine built with React.js that fetches quotes from an API and displays them dynamically. Click a button to get a new inspiring quote!",
    projectImage: "./images/projects/random-quote-machine.webp",
    projectRepo: "https://github.com/Mohamed-Waled/Random-Quote-Machine",
    projectLive: "https://random-quote-machine-lyart.vercel.app/",
    technologies: [
      {
        name: "react.js",
        icon: "FaReact",
      },
      {
        name: "SASS",
        icon: "SiSass",
      },
    ],
    isTraining: true,
  },
  {
    id: 7,
    projectName: "Real Pros",
    projectNameDescription: "call center company website.",
    projectDescription:
      "A website highlighting the features and pricing plans of the call center company RealPros. Multiple forms are included on the website to assist the owner in tracking where users submitted their quotes, giving them information about how far prospective customers were able to explore the site. This feature improves lead tracking and makes it possible to comprehend user behavior better.",
    projectImage: "./images/projects/real-pros.webp",
    projectRepo:
      "https://github.com/Mohamed-Waled/RealPros-Call-Center-Company",
    projectLive: "https://callcenter-company.mohamedwaled.com/",
    technologies: [
      {
        name: "react.js",
        icon: "FaReact",
      },
      {
        name: "next.js",
        icon: "SiNextdotjs",
      },
      {
        name: "SASS",
        icon: "SiSass",
      },
      {
        name: "Frame Motion",
        icon: "SiFramer",
      },
    ],
    featured: true,
  },
];

export default projectData;
